<script>
//import { required, email } from "vuelidate/lib/validators";
import { MazPhoneNumberInput } from "maz-ui";
import axios from "axios";
import router from '@/router/index'

const apiUrl = "https://174.138.181.206";

export default {
  data() {
    return {
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false, 
      designation: "",
      ifu: "",
      nbreVehicule: "",
      ville: "",
      email: "",
      password: "",
      responsable: "",
      tel: "",
      indicatifTel: "",
    };
  },
  components: { MazPhoneNumberInput },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  methods: {
    /*...authMethods,
    ...authFackMethods,
    ...notificationMethods,*/
    // Try to register the user in with the email, username
    // and password they provided.
    async tryToRegisterIn() {
      this.submitted = true;
      //this.$v.$touch();

      /*if (this.$v.$invalid) {
        console.log("Invalid:");
        return;
      } else {*/
      const {
        designation,
        ifu,
        nbreVehicule,
        ville,
        email,
        password,
        responsable,
        tel,
        indicatifTel,
      } = this;

      try {
        const options = {
          method: "POST",
          url: `${apiUrl}/agence/register`,
          data: {
            designation,
            ifu,
            nbreVehicule,
            ville,
            email,
            password,
            responsable,
            tel,
            indicatifTel,
          },
          headers: {},
        };

        const resp = await axios(options);

        if (!resp) throw new Error("Error from API: " + resp);
        //return resp;
        this.registerSuccess = true;
        router.push('/');
      } catch (err) {
        console.error("[ERROR]:", err);
        const resp = err.response;
        if (resp && resp.status === 401) {
          sessionStorage.removeItem("user");
        } else {
          //throw new Error(err);
          this.regError = err;
          this.isRegisterError = true;
        }
      }

      /*console.log("data:", data);
        if (data.data) {
          this.registerSuccess = true;
        } else {
          this.regError = "";
          this.isRegisterError = true;
        }
      }*/
    },
  },
};
</script>

<template>
  <div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-6">
            <div
              class="authentication-page-content p-4 d-flex align-items-center min-vh-100"
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="p-2 mt-5">
                        <b-alert
                          v-model="registerSuccess"
                          class="mt-3"
                          variant="success"
                          dismissible
                          >Inscription réussi.</b-alert
                        >

                        <b-alert
                          v-model="isRegisterError"
                          class="mt-3"
                          variant="danger"
                          dismissible
                          >{{ regError }}</b-alert
                        >

                        <b-alert
                          variant="danger"
                          class="mt-3"
                          v-if="notification.message"
                          show
                          dismissible
                          >{{ notification.message }}</b-alert
                        >

                        <div class="text-center mb-5">
                          <h2 class="font-size-26 mt-0">Formulaire d'inscription</h2>
                        </div>
                        <form
                          class="form-horizontal"
                          @submit.prevent="tryToRegisterIn"
                        >
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="">Nom de la société</label>
                                <input
                                  type="text"
                                  name=""
                                  id=""
                                  class="form-control"
                                  v-model="designation"
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="">Ville</label>
                                <input
                                  type="text"
                                  name=""
                                  id=""
                                  class="form-control"
                                  v-model="ville"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="">IFU</label>
                                <input
                                  type="text"
                                  name=""
                                  id=""
                                  class="form-control"
                                  v-model="ifu"
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="">Nombre de véhicule</label>
                                <input
                                  type="number"
                                  name=""
                                  id=""
                                  class="form-control"
                                  v-model="nbreVehicule"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="">Email</label>
                                <input
                                  type="email"
                                  name=""
                                  id=""
                                  class="form-control"
                                  v-model="email"
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="">Mot de passe</label>
                                <input
                                  type="password"
                                  name=""
                                  id=""
                                  class="form-control"
                                  v-model="password"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="">Numéro de téléphone</label>
                                <MazPhoneNumberInput
                                  v-model="tel"
                                  default-country-code="BJ"
                                  :translations="{
                                    countrySelectorLabel: 'Code pays',
                                    countrySelectorError: 'Choisir un pays',
                                    phoneNumberLabel: 'Numéro de téléphone',
                                  }"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="">Nom du responsable</label>
                                <input
                                  type="text"
                                  name=""
                                  id=""
                                  class="form-control"
                                  v-model="responsable"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              S'inscrire
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
